/* eslint-disable @typescript-eslint/ban-ts-comment */
import { z } from "zod";

/**
 * Specify your server-side environment variables schema here.
 * This way you can ensure the app isn't built with invalid env vars.
 */
const server = z.object({
  DATABASE_URL: z.string().url(),
  NODE_ENV: z.enum(["development", "test", "production"]),
  // NEXTAUTH_SECRET:
  //   process.env.NODE_ENV === "production"
  //     ? z.string().min(1)
  //     : z.string().min(1).optional(),
  // NEXTAUTH_URL: z.preprocess(
  //   // This makes Vercel deployments not fail if you don't set NEXTAUTH_URL
  //   // Since NextAuth.js automatically uses the VERCEL_URL if present.
  //   (str) => process.env.VERCEL_URL ?? str,
  //   // VERCEL_URL doesn't include `https` so it cant be validated as a URL
  //   process.env.VERCEL ? z.string().min(1) : z.string().url()
  // ),
  // Add `.min(1) on ID and SECRET if you want to make sure they're not empty
  DISCORD_CLIENT_ID: z.string(),
  DISCORD_CLIENT_SECRET: z.string(),
  ABLY_API_KEY: z.string(),
  ABLY_PUBLISHER_API_KEY: z.string(),
  REDIS_USERNAME: z.string(),
  REDIS_PASSWORD: z.string(),
  REDIS_ENDPOINT: z.string(),
  POSTMARK_API_TOKEN: z.string(),
  SMTP_HOST: z.string(),
  SMTP_PORT: z.string(),
  SMTP_USER: z.string(),
  SMTP_PASSWORD: z.string(),
  SMTP_FROM: z.string(),
  CLERK_API_KEY: z.string(),
  CLERK_WEBHOOK_SIGNING_SECRET: z.string(),
  NEXT_STRIPE_SECRET_KEY: z.string(),
  STRIPE_BASIC_MEMBERSHIP_PRICE_ID: z.string(),
  STRIPE_GOLD_MEMBERSHIP_PRICE_ID: z.string(),
  STRIPE_BASIC_MEMBERSHIP_PRICE_ID_V3: z.string(),
  STRIPE_GOLD_MEMBERSHIP_PRICE_ID_V3: z.string(),
  STRIPE_WEBHOOK_SECRET: z.string(),
  RESEND_API_KEY: z.string(),
  CRON_SECRET: z.string(),
  STRIPE_SECRET_KEY_POD_ENTERPRISE: z.string(),
  STRIPE_WEBHOOK_SECRET_POD_ENTERPRISE: z.string(),
  TELEGRAM_BOT_TOKEN: z.string(),
  TELEGRAM_CHANNEL_ID: z.string(),
  LOOPS_API_KEY: z.string(),
});

/**
 * Specify your client-side environment variables schema here.
 * This way you can ensure the app isn't built with invalid env vars.
 * To expose them to the client, prefix them with `NEXT_PUBLIC_`.
 */
const client = z.object({
  // NEXT_PUBLIC_CLIENTVAR: z.string().min(1),
  NEXT_PUBLIC_POSTHOG_ID: z.string(),
  NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY: z.string(),
  NEXT_PUBLIC_DOLPHIN_API_URL: z.string(),
  NEXT_PUBLIC_IPGEOLOCATION_API_KEY: z.string(),
  NEXT_PUBLIC_STRIPE_CUSTOMER_PORTAL_URL: z.string(),
  NEXT_PUBLIC_AG_GRID_ENTERPRISE_LICENSE_KEY: z.string(),
  NEXT_PUBLIC_CANCELLATION_RETENTION_COUPON_ID: z.string(),
  NEXT_PUBLIC_FIREBASE_API_KEY: z.string(),
  NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN: z.string(),
  NEXT_PUBLIC_FIREBASE_PROJECT_ID: z.string(),
  NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET: z.string(),
  NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID: z.string(),
  NEXT_PUBLIC_FIREBASE_APP_ID: z.string(),
  NEXT_PUBLIC_FIREBASE_VAPID_KEY: z.string(),
});

/**
 * You can't destruct `process.env` as a regular object in the Next.js
 * edge runtimes (e.g. middlewares) or client-side so we need to destruct manually.
 * @type {Record<keyof z.infer<typeof server> | keyof z.infer<typeof client>, string | undefined>}
 */
const processEnv = {
  DATABASE_URL: process.env.DATABASE_URL,
  NODE_ENV: process.env.NODE_ENV,
  // NEXTAUTH_SECRET: process.env.NEXTAUTH_SECRET,
  // NEXTAUTH_URL: process.env.NEXTAUTH_URL,
  DISCORD_CLIENT_ID: process.env.DISCORD_CLIENT_ID,
  DISCORD_CLIENT_SECRET: process.env.DISCORD_CLIENT_SECRET,
  ABLY_API_KEY: process.env.ABLY_API_KEY,
  ABLY_PUBLISHER_API_KEY: process.env.ABLY_PUBLISHER_API_KEY,
  REDIS_USERNAME: process.env.REDIS_USERNAME,
  REDIS_PASSWORD: process.env.REDIS_PASSWORD,
  REDIS_ENDPOINT: process.env.REDIS_ENDPOINT,
  POSTMARK_API_TOKEN: process.env.POSTMARK_API_TOKEN,
  SMTP_HOST: process.env.SMTP_HOST,
  SMTP_PORT: process.env.SMTP_PORT,
  SMTP_USER: process.env.SMTP_USER,
  SMTP_PASSWORD: process.env.SMTP_PASSWORD,
  SMTP_FROM: process.env.SMTP_FROM,
  CLERK_API_KEY: process.env.CLERK_API_KEY,
  CLERK_WEBHOOK_SIGNING_SECRET: process.env.CLERK_WEBHOOK_SIGNING_SECRET,
  NEXT_STRIPE_SECRET_KEY: process.env.NEXT_STRIPE_SECRET_KEY,
  NEXT_PUBLIC_POSTHOG_ID: process.env.NEXT_PUBLIC_POSTHOG_ID,
  NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY:
    process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY,
  STRIPE_BASIC_MEMBERSHIP_PRICE_ID:
    process.env.STRIPE_BASIC_MEMBERSHIP_PRICE_ID,
  STRIPE_GOLD_MEMBERSHIP_PRICE_ID: process.env.STRIPE_GOLD_MEMBERSHIP_PRICE_ID,
  STRIPE_BASIC_MEMBERSHIP_PRICE_ID_V3:
    process.env.STRIPE_BASIC_MEMBERSHIP_PRICE_ID_V3,
  STRIPE_GOLD_MEMBERSHIP_PRICE_ID_V3:
    process.env.STRIPE_GOLD_MEMBERSHIP_PRICE_ID_V3,
  STRIPE_WEBHOOK_SECRET: process.env.STRIPE_WEBHOOK_SECRET,
  NEXT_PUBLIC_DOLPHIN_API_URL: process.env.NEXT_PUBLIC_DOLPHIN_API_URL,
  NEXT_PUBLIC_IPGEOLOCATION_API_KEY:
    process.env.NEXT_PUBLIC_IPGEOLOCATION_API_KEY,
  NEXT_PUBLIC_STRIPE_CUSTOMER_PORTAL_URL:
    process.env.NEXT_PUBLIC_STRIPE_CUSTOMER_PORTAL_URL,
  NEXT_PUBLIC_AG_GRID_ENTERPRISE_LICENSE_KEY:
    process.env.NEXT_PUBLIC_AG_GRID_ENTERPRISE_LICENSE_KEY,
  RESEND_API_KEY: process.env.RESEND_API_KEY,
  CRON_SECRET: process.env.CRON_SECRET,
  STRIPE_SECRET_KEY_POD_ENTERPRISE:
    process.env.STRIPE_SECRET_KEY_POD_ENTERPRISE,
  STRIPE_WEBHOOK_SECRET_POD_ENTERPRISE:
    process.env.STRIPE_WEBHOOK_SECRET_POD_ENTERPRISE,
  TELEGRAM_BOT_TOKEN: process.env.TELEGRAM_BOT_TOKEN,
  TELEGRAM_CHANNEL_ID: process.env.TELEGRAM_CHANNEL_ID,
  LOOPS_API_KEY: process.env.LOOPS_API_KEY,
  NEXT_PUBLIC_CANCELLATION_RETENTION_COUPON_ID:
    process.env.NEXT_PUBLIC_CANCELLATION_RETENTION_COUPON_ID,
  NEXT_PUBLIC_FIREBASE_API_KEY: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
  NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN:
    process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
  NEXT_PUBLIC_FIREBASE_PROJECT_ID: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
  NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET:
    process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
  NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID:
    process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
  NEXT_PUBLIC_FIREBASE_APP_ID: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
  NEXT_PUBLIC_FIREBASE_VAPID_KEY: process.env.NEXT_PUBLIC_FIREBASE_VAPID_KEY,
};

// Don't touch the part below
// --------------------------

const merged = server.merge(client);
/** @type z.infer<merged>
 *  @ts-ignore - can't type this properly in jsdoc */
let env = process.env;

if (!!process.env.SKIP_ENV_VALIDATION == false) {
  const isServer = typeof window === "undefined";

  const parsed = isServer
    ? merged.safeParse(processEnv) // on server we can validate all env vars
    : client.safeParse(processEnv); // on client we can only validate the ones that are exposed

  if (parsed.success === false) {
    console.error(
      "❌ Invalid environment variables:",
      parsed.error.flatten().fieldErrors
    );
    throw new Error("Invalid environment variables");
  }

  /** @type z.infer<merged>
   *  @ts-ignore - can't type this properly in jsdoc */
  env = new Proxy(parsed.data, {
    get(target, prop) {
      if (typeof prop !== "string") return undefined;
      // Throw a descriptive error if a server-side env var is accessed on the client
      // Otherwise it would just be returning `undefined` and be annoying to debug
      if (!isServer && !prop.startsWith("NEXT_PUBLIC_"))
        throw new Error(
          process.env.NODE_ENV === "production"
            ? "❌ Attempted to access a server-side environment variable on the client"
            : `❌ Attempted to access server-side environment variable '${prop}' on the client`
        );
      /*  @ts-ignore - can't type this properly in jsdoc */
      return target[prop];
    },
  });
}

export { env };
