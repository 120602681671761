import { type AppType } from "next/app";
import {
  IBM_Plex_Sans_Condensed,
  IBM_Plex_Mono,
  Inter,
} from "@next/font/google";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { dark } from "@clerk/themes";
import { api } from "../utils/api";
import { posthog } from "posthog-js";
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-balham.css"; // Optional theme CSS
import "../styles/globals.css";
import "../styles/landing.css";
import "react-loading-skeleton/dist/skeleton.css";
import { KBarAnimator, KBarPortal, KBarPositioner, KBarProvider } from "kbar";
import { CMDKBar } from "../components/CMDKBar/CMDKBar";
import { useCallback, useEffect } from "react";
import { Analytics } from "@vercel/analytics/react";
import { env } from "../env.mjs";
import { SkeletonTheme } from "react-loading-skeleton";
import { ClerkProvider, useUser } from "@clerk/nextjs";
import { ThemeProvider } from "../components/ThemeProvider/ThemeProvider";
import { LicenseManager } from "@ag-grid-enterprise/core";
import Script from "next/script";
import { useEventsStore } from "../store/store";
import Head from "next/head";
import useFCM from "../hooks/notifications/useFCM";
import { getFCMToken } from "../utils/pushNotifications/getFCMToken";
import firebaseApp from "../firebaseInit";
import { getMessaging } from "firebase/messaging";
import { Toaster } from "../components/ui/sonner";

LicenseManager.setLicenseKey(env.NEXT_PUBLIC_AG_GRID_ENTERPRISE_LICENSE_KEY);

const ibmPlexMono = IBM_Plex_Mono({
  subsets: ["latin"],
  weight: ["100", "200", "300", "400", "500", "600", "700"],
});
const ibmPlexSansCondensed = IBM_Plex_Sans_Condensed({
  subsets: ["latin"],
  weight: ["100", "200", "300", "400", "500", "600", "700"],
});
const inter = Inter({
  subsets: ["latin"],
  weight: ["100", "200", "300", "400", "500", "600", "700", "800"],
});

const MyApp: AppType = ({ Component, pageProps }) => {
  useEffect(() => {
    posthog.init(env.NEXT_PUBLIC_POSTHOG_ID, {
      api_host: "https://eu.posthog.com",
      autocapture: false,
      enable_recording_console_log: true,
      session_recording: {
        maskAllInputs: false,
      },
    });
  }, []);

  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <title>POD | Pinnacle Odds Dropper</title>
        <meta
          name="description"
          content="Get notified when Pinnacle's odds drop"
        />
        <link rel="shortcut icon" href="/favicon-32x32.png" />
        <meta name="theme-color" content="#0a0a0a" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:url" content="https://pinnacleoddsdropper.com" />
        <meta name="twitter:title" content="POD | Pinnacle Odds Dropper" />
        <meta
          name="twitter:description"
          content="Get notified when Pinnacle's odds drop"
        />
        <meta
          name="twitter:image"
          content="https://s3.amazonaws.com/appforest_uf/f1675891443939x751057183610460000/socialmedia-1675887595542x750744149380563000.png"
        />
        <meta name="twitter:creator" content="@PODTerminal" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Pinnacle Odds Dropper" />
        <meta
          property="og:description"
          content="Get notified when Pinnacle's odds drop"
        />
        <meta property="og:site_name" content="POD | Pinnacle Odds Dropper" />
        <meta property="og:url" content="https://pinnacleoddsdropper.com" />
        <meta
          property="og:image"
          content="https://s3.amazonaws.com/appforest_uf/f1675891443939x751057183610460000/socialmedia-1675887595542x750744149380563000.png"
        />
        {/* Favicon stuff */}
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        ></link>
        {/* Progressier */}
        <link
          rel="manifest"
          href="https://progressier.app/cbLRxuXbC25pPWRYfuyQ/progressier.json"
        />
      </Head>
      <ClerkProvider
        appearance={{
          baseTheme: dark,
        }}
        {...pageProps}
      >
        <style jsx global>{`
          :root {
            --ibm-plex-sans: ${ibmPlexSansCondensed.style.fontFamily};
            --ibm-plex-mono: ${ibmPlexMono.style.fontFamily};
            --inter: ${inter.style.fontFamily};
          }
        `}</style>
        {/* <KBarProvider actions={actions}> */}
        <KBarProvider>
          <KBarPortal>
            <KBarPositioner className="z-50">
              <KBarAnimator>
                <CMDKBar />
              </KBarAnimator>
            </KBarPositioner>
          </KBarPortal>
          <SkeletonTheme baseColor="#202020" highlightColor="#444">
            {/* <ErrorBoundary> */}
            <ThemeProvider
              attribute="class"
              defaultTheme="dark"
              // enableSystem
              disableTransitionOnChange
            >
              <Component {...pageProps} />
              <Rewardful />
              <Toaster />
              <FirebaseMessaging />
            </ThemeProvider>
            {/* </ErrorBoundary> */}
            <Analytics />
          </SkeletonTheme>
        </KBarProvider>
        <PosthogThingy />
        {/* <ReactQueryDevtools initialIsOpen={false} /> */}
      </ClerkProvider>
    </>
  );
};

export default api.withTRPC(MyApp);

const Rewardful = () => {
  const setRewardfulReferral = useEventsStore(
    (state) => state.setRewardfulReferral
  );

  return (
    <>
      <Script id="rewardful-init">
        {`(function(w,r){w._rwq=r;w[r]=w[r]||function(){(w[r].q=w[r].q||[]).push(arguments)}})(window,'rewardful');`}
      </Script>
      <Script
        id="rewardful-script"
        src="https://r.wdfl.co/rw.js"
        data-rewardful="db8977"
        strategy="lazyOnload"
        onLoad={() => {
          console.log("🟢 rewardful", window.rewardful);
          window.rewardful?.("ready", () => {
            setRewardfulReferral(window.Rewardful.referral);
          });
        }}
      />
    </>
  );
};

const PosthogThingy = () => {
  const { user, isSignedIn } = useUser();
  const primaryEmailAddress = user?.primaryEmailAddress?.emailAddress;
  const userId = user?.id;

  useEffect(() => {
    if (isSignedIn && primaryEmailAddress && userId) {
      posthog?.identify(userId, {
        email: primaryEmailAddress,
      });
    }
  }, [userId, primaryEmailAddress, isSignedIn]);
  return <></>;
};

const FirebaseMessaging = () => {
  useFCM();

  const notificationPermission = useEventsStore(
    (state) => state.notificationPermission
  );
  const setNotificationPermission = useEventsStore(
    (state) => state.setNotificationPermission
  );
  const setFCMToken = useEventsStore((state) => state.setFCMToken);
  const setIsFCMTokenLoading = useEventsStore(
    (state) => state.setIsFCMTokenLoading
  );

  const updatePermission = useCallback(() => {
    if (typeof Notification !== "undefined") {
      setNotificationPermission(Notification.permission);
    }
  }, [setNotificationPermission]);

  useEffect(() => {
    console.log("firebase Notification permission:", notificationPermission);
    const init = async () => {
      if (notificationPermission === "granted" && firebaseApp) {
        setIsFCMTokenLoading(true);
        const messaging = getMessaging(firebaseApp);
        const fcmToken = await getFCMToken(messaging, notificationPermission);

        if (fcmToken) {
          setFCMToken(fcmToken);
        }
        setIsFCMTokenLoading(false);
      }
    };
    init().catch((e) => {
      console.error("🔴 Error initializing Firebase Messaging", e);
    });
  }, [notificationPermission, setFCMToken, setIsFCMTokenLoading]);

  useEffect(() => {
    updatePermission();

    if (typeof navigator !== "undefined" && "permissions" in navigator) {
      navigator.permissions
        .query({ name: "notifications" as PermissionName })
        .then((notificationPerm) => {
          notificationPerm.onchange = updatePermission;
        })
        .catch(console.error);
    }
  }, [updatePermission]);

  return <></>;
};
